import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function AltitudeLimitIndicationTable2p0() {
  return (
    <Paper sx={{ p: "1rem" }}>
      <Box sx={{ mb: "0.5rem" }}>
        <Typography>FPA2.0°降下時の高度制限指示表(抜粋)</Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cruise FL</TableCell>
              <TableCell>FL390</TableCell>
              <TableCell>FL370</TableCell>
              <TableCell>FL350</TableCell>
              <TableCell>FL330</TableCell>
              <TableCell>FL310</TableCell>
              <TableCell>FL290</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gate FL</TableCell>
              <TableCell>FL380</TableCell>
              <TableCell>FL360</TableCell>
              <TableCell>FL340</TableCell>
              <TableCell>FL320</TableCell>
              <TableCell>FL300</TableCell>
              <TableCell>FL280</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>SUC</TableCell>
              <TableCell>22NM SW</TableCell>
              <TableCell>12NM SW</TableCell>
              <TableCell>3NM SW</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>STORK</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>11NM SW</TableCell>
              <TableCell>1NM SW</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
          <TableRow>
            <TableCell>BRAVE</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>36NM SW</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>KARIN</TableCell>
            <TableCell>103NM SW</TableCell>
            <TableCell>94NM SW</TableCell>
            <TableCell>84NM SW</TableCell>
            <TableCell>75NM SW</TableCell>
            <TableCell>66NM SW</TableCell>
            <TableCell>56NM SW</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Paper>
  );
}
