import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useParams } from "react-router-dom";
import HomeAppBar from "../../../../../components/HomeAppBar";
import PilotDashboardDrawer from "../../../../../components/PilotDashboardDrawer";
import Turbulence from "../../../../../components/Turbulence";
import { useFlightDetailQuery } from "../../../../../services/dashboard-api";
import { orangeColor } from "../../../../../theme";

import html2canvas from "html2canvas";
import PdfComponent from "../../../../../components/PdfComponent";
(window as any).html2canvas = html2canvas;
// https://github.com/MrRio/jsPDF/issues/1225
const { jsPDF } = require("jspdf");

interface RouterParams {
  callSign: string;
  departureDateUtc: string;
}

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PilotFpaDetail() {
  const { callSign, departureDateUtc } = useParams<RouterParams>();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openDialogDownload, setOpenDialogDownload] = React.useState(false);

  const handleClickOpenDownload = () => {
    setOpenDialogDownload(true);
    if (route) {
      const input = document.getElementById("sheet")!; // idを走査する
      html2canvas(input, { scale: 1.0 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/svg", 1.0); // 一度svgにする。pngでもjpegでもok
        let pdf = new jsPDF(); // pdfを生成
        pdf.addImage(
          imgData,
          "SVG",
          5,
          10,
          canvas.width / 5,
          canvas.height / 5
        ); //　ここが大変だった
        pdf.save(`${callSign}_${departureDateUtc}_fpa${fpaDegree}.pdf`); // ダウンロードが開始される .pdf
      });
    }
  };

  const handleCloseDownload = () => {
    setOpenDialogDownload(false);
  };

  const [openDialogApprove, setOpenDialogApprove] = React.useState(false);

  const handleClickOpenApprove = () => {
    setOpenDialogApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenDialogApprove(false);
  };

  const [approve, setApprove] = React.useState(false);
  const [openApprove, setOpenApprove] = React.useState(false);

  const handleApprove = () => {
    setApprove(true);
    setOpenDialogApprove(false);
    setOpenApprove(true);
  };

  const handleCloseApproved = () => {
    setOpenApprove(false);
  };

  const handleCancelApprove = () => {
    setApprove(false);
    setOpenDialogCancelApprove(false);
    setOpenCancel(true);
  };

  const [openDialogCancelApprove, setOpenDialogCancelApprove] =
    React.useState(false);

  const handleClickOpenCancelApprove = () => {
    setOpenDialogCancelApprove(true);
  };

  const handleCloseCancelApprove = () => {
    setOpenDialogCancelApprove(false);
  };

  const [openCancel, setOpenCancel] = React.useState(false);

  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const {
    data: route,
    isLoading: isLoadingRoute,
    error: errorRoute,
  } = useFlightDetailQuery([callSign, departureDateUtc]);

  const [fpaDegree, setFpaDegree] = React.useState("2.0");

  const WithPDFAbleContent = () => {
    return (
      <>
        <Paper id="sheet">
          {route && <PdfComponent {...{ route, fpaDegree }}></PdfComponent>}
        </Paper>
      </>
    );
  };

  const FlightInfoComponent = () => (
    <Grid container spacing={2}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {route && <WithPDFAbleContent />}
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="fpa-select-helper-label">FPA</InputLabel>
                <Select
                  labelId="fpa-select-helper-label"
                  id="fpa-select-helper"
                  value={fpaDegree}
                  label="FPA"
                  onChange={(e) => {
                    setFpaDegree(e.target.value);
                  }}
                >
                  {route &&
                    route.fpa_deg_list.map((fpaDeg) => (
                      <MenuItem value={fpaDeg}>{fpaDeg}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography
                  sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  gutterBottom
                >
                  Fuel score
                </Typography>
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  color={orangeColor}
                  display="inline"
                >
                  {route &&
                    `${
                      Math.round(
                        route.fuel_saving[
                          fpaDegree as keyof typeof route.fuel_saving
                        ] * 10
                      ) / 10
                    }`}
                </Typography>
                &nbsp;
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const DialogComponent = () => (
    <>
      <Dialog
        open={openDialogDownload}
        onClose={handleCloseDownload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Generating..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It may take several tens of seconds. <br />
            Close this dialog when the file is downloaded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDownload}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogApprove}
        onClose={handleCloseApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you approve this plan?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseApprove}>Cancel</Button>
          <Button onClick={handleApprove} color="warning">
            APPV
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openApprove}
        onClose={handleCloseApproved}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Approved."}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseApproved}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogCancelApprove}
        onClose={handleCloseCancelApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancel approval?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseCancelApprove}>Cancel</Button>
          <Button onClick={handleCancelApprove} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancel}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Canceled."}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        {route && (
          <HomeAppBar
            open={open}
            title={
              route.checkpoint_waypoint +
              " " +
              String(route.checkpoint_altitude_ft) +
              " FPA " +
              fpaDegree
            }
            onClick={handleDrawerOpen}
          ></HomeAppBar>
        )}
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          {!value && (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpenDownload}
              >
                Download
              </Button>
              {!approve && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleClickOpenApprove}
                >
                  Approve
                </Button>
              )}
              {approve && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickOpenCancelApprove}
                >
                  Cancel APPD
                </Button>
              )}
            </Stack>
          )}
          {value === 1 && (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpenDownload}
              >
                Download
              </Button>
            </Stack>
          )}
          {value > 1 && (
            <Container fixed>
              <Box sx={{ height: "4.4vh" }} />
            </Container>
          )}
          <DialogComponent></DialogComponent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Flight Information" {...a11yProps(0)} />
              <Tab label="Turbulence Data" {...a11yProps(1)} />
              <Tab label="Flight Plan" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Container>
              {isLoadingRoute && <LinearProgress />}
              {!isLoadingRoute && <FlightInfoComponent />}
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Turbulence></Turbulence>
          </TabPanel>
          <TabPanel value={value} index={2}>
            Flight Plan
          </TabPanel>
        </Main>
      </Box>
    </>
  );
}
