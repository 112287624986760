import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import * as React from "react";
import ariesConfigs from "../configs/aries-configs";
import { useFlightListQuery } from "../services/dashboard-api";
import theme, { orangeColor } from "../theme";

function fpaApprovedIcon(fpa_approved: number) {
  if (fpa_approved) {
    return <FlagIcon sx={{ color: orangeColor }}></FlagIcon>;
  } else {
    return <FlagOutlinedIcon sx={{ color: orangeColor }}></FlagOutlinedIcon>;
  }
}

function tableStatusStyle(status: string) {
  if (status === "Before Departure") {
    return { backgroundColor: theme.palette.grey[300], color: "white" };
  } else if (status === "In Flight") {
    return { backgroundColor: theme.palette.grey[300], color: "white" };
  } else {
    return {};
  }
}

interface Data {
  fpa_approved: number;
  PK: string;
  SK: string;
  status: string;
  scheduled_departure_time_utc: string;
  departure_airport: string;
  arrival_airport: string;
  fuel_saving: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: "fpa_approved",
  //   numeric: true,
  //   label: "FPA Approval",
  // },
  {
    id: "PK",
    numeric: false,
    label: "Call Sign",
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
  },
  {
    id: "scheduled_departure_time_utc",
    numeric: false,
    label: "Scheduled departure time",
  },
  {
    id: "departure_airport",
    numeric: false,
    label: "Departure Airport",
  },
  {
    id: "arrival_airport",
    numeric: false,
    label: "Arrival Airport",
  },
  // {
  //   id: 'fuel_saving',
  //   numeric: true,
  //   label: 'Fuel score',
  // },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

type Props = {
  order: Order;
  orderBy: keyof Data;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
};

export default function PilotFlightInformationTable(props: Props) {
  const account = "APJ";
  let departureDateUtc: string;
  if (ariesConfigs.ariesEnv === "sandbox") {
    departureDateUtc = "2022-07-20";
  } else {
    departureDateUtc = dayjs().format("YYYY-MM-DD");
  }
  const { data: flightInfos, isLoading } = useFlightListQuery([
    account,
    departureDateUtc,
  ]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Paper sx={{ m: 2, p: 2, display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontSize: "1rem" }}>
        {flightInfos && flightInfos[0].SK.slice(-10)}
      </Typography>
      <Table size="small">
        <EnhancedTableHead
          order={props.order}
          orderBy={props.orderBy}
          onRequestSort={props.onRequestSort}
        />
        <TableBody>
          {flightInfos &&
            stableSort(
              flightInfos,
              getComparator(props.order, props.orderBy)
            ).map((row, index) => {
              return (
                <TableRow key={row.PK.slice(7)}>
                  {/* <TableCell align="center">
                    {fpaApprovedIcon(row.fpa_approved)}
                  </TableCell> */}
                  <TableCell>{row.PK.slice(7)}</TableCell>
                  <TableCell style={tableStatusStyle(row.status)}>
                    {row.status}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_departure_time_utc.replace(":", "") + "Z"}
                  </TableCell>
                  <TableCell>{row.departure_airport.split("/")[0]}</TableCell>
                  <TableCell>{row.arrival_airport.split("/")[0]}</TableCell>
                  {/* <TableCell align="right" ><Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', color: orangeColor }} display="inline">{Math.round(row.fuel_saving * 10) / 10}</Typography></TableCell> */}
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      href={
                        "/pilot/call-signs/" +
                        row.PK.slice(7) +
                        "/departure-date-utc/" +
                        row.SK.slice(-10) +
                        "/flight-level"
                      }
                    >
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
}
