import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useParams } from "react-router-dom";
import DownloadProcess from "../../../../../components/DownloadProcess";
import HomeAppBar from "../../../../../components/HomeAppBar";
import PdfFlightLevelComponent from "../../../../../components/PdfFlightLevelComponent";
import PilotDashboardDrawer from "../../../../../components/PilotDashboardDrawer";
import {
  useFlightDetailQuery,
  useFlightInfoQuery
} from "../../../../../services/dashboard-api";
import { RouteComponent } from "../../../../../services/types";

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface RouterParams {
  callSign: string;
  departureDateUtc: string;
  flightLevel: string;
}

export default function PilotFlightLevelFpaDetail() {
  const { callSign, departureDateUtc, flightLevel } = useParams<RouterParams>();

  const [open, setOpen] = React.useState(false);
  const [openDialogDownload, setOpenDialogDownload] = React.useState(false);
  const [downloadProgress, setDownloadProgress] = React.useState<
    "determinate" | "indeterminate"
  >("indeterminate");

  React.useEffect(() => {
    console.log(openDialogDownload, route);
    if (openDialogDownload && route) {
      console.log("start to download.");
      const input = document.getElementById("sheet")!; // idを走査する
      DownloadProcess({
        input: input,
        savedFileName: `${callSign}_${departureDateUtc}_FL${flightLevel}.pdf`,
      }).then(() => setDownloadProgress("determinate"));
    }
  }, [openDialogDownload]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickOpenDownload = () => {
    setDownloadProgress("indeterminate");
    setOpenDialogDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDialogDownload(false);
  };

  const {
    data: flightInfo,
    isLoading: isLoadingFlightInfo,
    error: errorFlightInfo,
  } = useFlightInfoQuery([callSign, departureDateUtc]);
  const {
    data: route,
    isLoading: isLoadingRoute,
    error: errorRoute,
  } = useFlightDetailQuery([callSign, departureDateUtc]);

  const DownloadDialogComponent = () => (
    <>
      <Dialog
        open={openDialogDownload}
        onClose={handleCloseDownload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Generating..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It may take several tens of seconds. <br />
            Close this dialog when the file is downloaded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDownload}>Close</Button>
        </DialogActions>
        <LinearProgress variant={downloadProgress} />
      </Dialog>
    </>
  );

  const ContentComponent = () => {
    if (isLoadingRoute || isLoadingFlightInfo) {
      return <LinearProgress />;
    }

    if (errorRoute || errorFlightInfo || !route || !flightInfo) {
      return <>Something wrong...</>;
    }

    let routeComponent: RouteComponent | null = null;
    switch (flightLevel) {
      case "290":
        routeComponent = route.FL290;
        break;
      case "310":
        routeComponent = route.FL310;
        break;
      case "330":
        routeComponent = route.FL330;
        break;
      case "350":
        routeComponent = route.FL350;
        break;
      case "370":
        routeComponent = route.FL370;
        break;
      case "390":
        routeComponent = route.FL390;
        break;
    }
    if (!routeComponent) {
      return <LinearProgress />;
    }

    return (
      <>
        <PdfFlightLevelComponent
          flightInfo={flightInfo}
          route={routeComponent}
          flightLevel={flightLevel}
          callSign={callSign}
          departureDateUtc={departureDateUtc}
          isToImage={false}
        />
        <div id="sheet" style={{ display: "none" }}>
          <PdfFlightLevelComponent
            flightInfo={flightInfo}
            route={routeComponent}
            flightLevel={flightLevel}
            callSign={callSign}
            departureDateUtc={departureDateUtc}
            isToImage={true}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <HomeAppBar
          open={open}
          title={`${callSign} / ${departureDateUtc}`}
          onClick={handleDrawerOpen}
        ></HomeAppBar>
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          <DownloadDialogComponent></DownloadDialogComponent>
          <Box sx={{ m: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ mb: "2rem" }}
            >
              <Button
                variant="contained"
                href={
                  "/pilot/call-signs/" +
                  callSign +
                  "/departure-date-utc/" +
                  departureDateUtc +
                  "/flight-level"
                }
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpenDownload}
                sx={{ ml: "auto" }}
              >
                Download
              </Button>
            </Stack>
            <ContentComponent />
          </Box>
        </Main>
      </Box>
    </>
  );
}
