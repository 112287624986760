import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      blue: string;
      green: string;
      yellow: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      blue?: string;
      green?: string;
      yellow?: string;
    };
  }
}

export const blueColor = "#24ACE4";
export const greenColor = "#4DA223";
export const yellowColor = "#FEAE2C";
export const yellowColorAlpha = "#FEAE2C33";
export const orangeColor = "#ff8c00";
export const whiteColor = "#FFFFFF";
export const redColor = "#C44444";
export const peachColor = "#d14e97";
export const grayColor = "gray";

// A custom theme for this app
let theme = createTheme({
  status: {
    blue: blueColor,
    green: greenColor,
    yellow: yellowColor,
  },
  palette: {
    // mode: "dark",
    primary: {
      main: blueColor,
      contrastText: whiteColor,
    },
    secondary: {
      main: greenColor,
    },
    error: {
      main: redColor,
    },
    warning: {
      main: orangeColor,
    },
  },
});

export default theme;
