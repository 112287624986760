import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { signOut } from "../services/auth-api";
import logo_nm from "./logo_nm.jpeg";

const drawerWidth: number = 240;

type Props = {
  open: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PilotDashboardDrawer(props: Props) {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <DrawerHeader>
        <Box
          component="img"
          sx={{
            height: 20,
            width: 40,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          src={logo_nm}
        />
        <Box sx={{ mx: "0.5rem" }}>NABLA Mobility</Box>
        <IconButton onClick={props.onClick}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem button key={"Flight Information"}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <Link
            component={RouterLink}
            to={"/"}
            underline="none"
            color="inherit"
          >
            {"Flight Information"}
          </Link>
        </ListItem>
        <ListItem button key={"Download files"}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <Link
            component={RouterLink}
            to={"/pilot/settings"}
            underline="none"
            color="inherit"
          >
            {"Settings"}
          </Link>
        </ListItem>
      </List>
      <List style={{ marginTop: `auto` }}>
        <ListItem>
          <ListItemText>
            <Box>
              <Button
                size="small"
                startIcon={<HelpOutlineIcon />}
                component={Link}
              >
                Help
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                startIcon={<LogoutIcon />}
                component={Link}
                onClick={signOut}
              >
                Sign out
              </Button>
            </Box>
            <Box sx={{ textAlign: "center", fontSize: 11, marginTop: 2 }}>
              &copy; NABLA Mobility
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}
