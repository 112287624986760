import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useFlightWindQuery } from "../services/dashboard-api";
import { yellowColorAlpha } from "../theme";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type Props = {
  callSign: string;
  departureDateUtc: string;
};
const WindData = ({ callSign, departureDateUtc }: Props) => {
  const {
    data: windData,
    isLoading: isWindDataLoading,
    error: windDataError,
  } = useFlightWindQuery([callSign, departureDateUtc]);

  if (isWindDataLoading) {
    return <></>;
  }

  if (windDataError) {
    console.error(windDataError);
    return <>Something wrong...</>;
  }

  if (!windData) {
    return <>No wind data</>;
  }

  const {
    waypoint_list,
    altitude_list,
    values,
    forecast_time_list,
    forecast_hours_list,
  } = windData;

  return (
    <Paper sx={{ padding: "1rem" }}>
      <Box sx={{ mb: "0.5rem" }}>
        <Typography>
          WIND DATA:{" "}
          {forecast_time_list
            .map((forecast_time, i) => {
              const day = ("00" + dayjs(forecast_time).date()).slice(-2);
              const hour = ("00" + dayjs(forecast_time).hour()).slice(-2);
              const forecast_hour = ("00" + forecast_hours_list[i]).slice(-2);
              return `${day}${hour}${forecast_hour}`;
            })
            .join(" - ")}
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>FL / POS</TableCell>
            {altitude_list.map((altitude) => (
              <TableCell key={altitude}>
                {altitude}
                <br />
                Wind Tem W/S
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {waypoint_list.map((waypoint, waypoint_index) => (
            <StyledTableRow key={waypoint}>
              <TableCell component="th">{waypoint}</TableCell>
              {altitude_list.map((altitude, altitude_index) => {
                const yellow = yellowColorAlpha;
                let backgroundColor = "transparent";
                backgroundColor =
                  parseInt(values[waypoint_index][altitude_index].slice(-2)) >=
                  6
                    ? yellow
                    : "transparent";
                const direction = values[waypoint_index][altitude_index].slice(
                  0,
                  3
                );
                const speed = values[waypoint_index][altitude_index].slice(
                  3,
                  6
                );
                const temperature = values[waypoint_index][
                  altitude_index
                ].slice(6, 9);
                const ws = values[waypoint_index][altitude_index].slice(10);
                return (
                  <TableCell
                    key={`${altitude}-${waypoint}`}
                    sx={{ backgroundColor }}
                  >
                    {/* {values[waypoint_index][altitude_index]} */}
                    {`${direction} ${speed} ${temperature} -${ws}`}
                  </TableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "0.5rem" }}>
        <Box>
          <span style={{ color: yellowColorAlpha }}>■</span>{" "}
          <span style={{ fontSize: "10pt" }}>
            W/S is 6 or higher if the background is yellow.{" "}
          </span>
        </Box>
      </Box>
    </Paper>
  );
};

export default WindData;
