import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Fragment } from "react";
import { AltitudeAtWaypointsComponents, Route } from "../services/types";

export default function PdfComponent(props: {
  route: Route;
  fpaDegree: string;
}) {
  return (
    <>
      <Typography sx={{ fontSize: "1rem" }} display="inline">
        FPA
      </Typography>
      &nbsp;
      <Typography
        sx={{ fontSize: "2rem", fontWeight: "bold" }}
        display="inline"
      >
        {`${props.fpaDegree}`}
      </Typography>
      &nbsp;
      <Typography sx={{ fontSize: "1rem" }} display="inline">
        {props.route && props.route.checkpoint_waypoint + " FL"}
      </Typography>
      &nbsp;
      <Typography
        sx={{ fontSize: "2rem", fontWeight: "bold" }}
        display="inline"
      >
        {`${
          props.route && Math.floor(props.route.checkpoint_altitude_ft / 100)
        }`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={props.route && props.route.navigation.length}
                  align="center"
                >
                  Target altitude [ft]
                </TableCell>
              </TableRow>
              <TableRow>
                {props.route &&
                  props.route.navigation.map((name) => (
                    <TableCell>{name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <Fragment>
                <TableRow>
                  {props.route &&
                    props.route.navigation.map((name) => (
                      <TableCell>
                        {props.route &&
                          Math.round(
                            props.route.altitude_at_waypoints[
                              name as keyof typeof props.route.altitude_at_waypoints
                            ][
                              props.fpaDegree as keyof AltitudeAtWaypointsComponents
                            ]
                          )}
                      </TableCell>
                    ))}
                </TableRow>
              </Fragment>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={3}>TOD Cruise altitude [FL]</TableCell>
                <TableCell
                  colSpan={props && props.route.navigation.length + 1}
                  align="center"
                >
                  FPA Entry Gate Point
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>FL</TableCell>
                <TableCell
                  colSpan={props.route && props.route.navigation.length}
                  align="center"
                >
                  Distance From Waypoints [NM]
                </TableCell>
              </TableRow>
              <TableRow>
                {props.route &&
                  props.route.navigation.map((name) => (
                    <TableCell>{name}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <Fragment>
                {props.route &&
                  props.route.distance_to_waypoints[
                    props.fpaDegree as keyof typeof props.route.distance_to_waypoints
                  ].map((value) => (
                    <TableRow>
                      <TableCell>{value.FL + 10}</TableCell>
                      <TableCell>{value.FL}</TableCell>
                      {props.route.navigation.map((name) => (
                        <TableCell>
                          {value[name as keyof typeof value].split(".")[0]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </Fragment>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
}
