import { Box, Stack } from "@mui/material";
import * as d3 from "d3";
import Plotly from "plotly.js-dist-min";
import { Shape } from "plotly.js-dist-min";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  useFlightCrossSectionQuery,
  useFlightWindQuery,
} from "../services/dashboard-api";
import { CrossSectionPoint, CrossSectionWindPoint } from "../services/types";
import theme from "../theme";

interface Props {
  callSign: string;
  departureDateUtc: string;
  flightLevel: string; // "390" | "370" | "350" | "330" | "310" | "290";
  isToImage?: boolean;
}

/* Component */
export const CrossSection = ({
  callSign,
  departureDateUtc,
  flightLevel,
  isToImage = false,
}: Props) => {
  const [data, setData] = useState<Plotly.Data[] | null>(null);
  const [layout, setLayout] = useState<Partial<Plotly.Layout> | null>(null);

  const {
    data: windData,
    isLoading: isWindDataLoading,
    error: windDataError,
  } = useFlightWindQuery([callSign, departureDateUtc]);

  const {
    data: crossSection,
    isLoading: isCrossSectionLoading,
    error: crossSectionError,
  } = useFlightCrossSectionQuery([callSign, departureDateUtc]);

  const name = uuidv4().slice(0, 8);
  const plotId = "plot-" + name;
  const imgId = "img-" + name;

  useEffect(() => {
    if (!windData) return;
    if (!crossSection) return;

    const {
      distance_mile_list: waypointDistanceMileList,
      waypoint_list: waypointList,
    } = windData;

    const { data: crossSectionData } = crossSection;
    const points: CrossSectionPoint[] = [];

    // EDR MAXについて配列に追加
    crossSectionData["edr_max"].forEach((levelPoints) => {
      points.push(...levelPoints.points);
    });

    // 高さ→距離でソート
    points.sort(
      (a, b) =>
        a.distance_mile -
        b.distance_mile +
        (a.vertical_ft - b.vertical_ft) * 1_000_000
    );

    // ユニークな距離／高度を取得
    const distanceMileList = points
      .map((point) => point.distance_mile)
      .filter((value, index, self) => self.indexOf(value) === index);
    const verticalFtList = points
      .map((point) => point.vertical_ft)
      .filter((value, index, self) => self.indexOf(value) === index);

    // EDR MAXを取得
    const edrMaxValues = points.map((point) => point.value);

    // 可視化のために２次元配列に入れ直す
    var edrMaxValues2D: number[][] = [];
    for (var i = 0; i < edrMaxValues.length; i += distanceMileList.length) {
      edrMaxValues2D.push(edrMaxValues.slice(i, i + distanceMileList.length));
    }

    const colorscale: Array<[number, string]> = [
      [0, "rgba(255,255,255,128)"],
      [0.0552, "rgba(255,255,255,128)"],
      [0.1242, "rgba(120,169,255,128)"], //
      [0.2208, "rgba(66,190,101,128)"],
      [0.345, "rgba(253,220,105,128)"],
      [0.4968, "rgba(255,131,43,128)"],
      [0.8832, "rgba(162,25,31,128)"],
      [1, "rgba(162,25,31,128)"],
    ];

    const turbulenceLevelNames = [
      "Smooth",
      "Light",
      "Light-to-Moderate",
      "Moderate",
      "Moderate-to-Severe",
      "Severe",
      "Extreme",
      "",
    ];

    // 2.0
    const fl2DistanceML: { [key: string]: number } = {
      "390": 285.8028937,
      "370": 295.228729,
      "350": 304.6545644,
      "330": 314.0803997,
      "310": 323.506235,
      "290": 332.9320704,
    };

    const verticalLines: Partial<Shape>[] = waypointDistanceMileList.map(
      (distanceMile) => {
        return {
          type: "line",
          x0: distanceMile,
          x1: distanceMile,
          y0: Math.min(...verticalFtList),
          y1: Math.max(...verticalFtList),
          line: {
            color: theme.palette.grey[300],
            width: 1,
          },
        };
      }
    );
    const horizontalLines: Partial<Shape>[] = [
      10000, 20000, 30000, 40000, 50000,
    ].map((verticalFt) => {
      return {
        type: "line",
        x0: 0,
        x1: Math.max(...distanceMileList),
        y0: verticalFt,
        y1: verticalFt,
        line: {
          color: theme.palette.grey[300],
          width: 0.5,
        },
      };
    });

    const shapes: Partial<Shape>[] = [...verticalLines, ...horizontalLines];

    // Wind
    const crossSectionLevelWindPoints = crossSectionData["wind"].find(
      (data) => data.level === "NONE"
    );
    let windImages: Partial<Plotly.Image>[] = [];
    let crossSectionWindPoints: CrossSectionWindPoint[] = [];

    // イメージに変換しない設定で、かつ風のデータがあったら
    if (!isToImage && crossSectionLevelWindPoints) {
      const getKnots = (point: CrossSectionWindPoint) =>
        (Math.round((point.speed_knot * 2) / 10) | 0) * 5;
      crossSectionWindPoints = crossSectionLevelWindPoints["points"]
        .filter(
          (point) => point.vertical_ft >= 9000 && point.vertical_ft <= 50000
        )
        .map((point) => ({
          ...point,
          barbKnot: getKnots(point),
        }));
      windImages = crossSectionWindPoints.map(
        (item: {
          barbKnot: number;
          distance_mile: number;
          vertical_ft: number;
          direction_deg: number;
        }) => ({
          source: `/wind_barbs/vertical/black/${item.barbKnot}_knots_black.svg`,
          layer: "above",
          xref: "x",
          yref: "y",
          x: item.distance_mile,
          y: item.vertical_ft,
          sizex: 10000, // image size depend on sizey
          sizey: 6000,
          xanchor: undefined,
          yanchor: "top",
          direction: item.direction_deg,
          barbKnot: item.barbKnot,
        })
      );
    }

    setData([
      {
        z: edrMaxValues2D,
        x: distanceMileList,
        y: verticalFtList,
        type: "contour",
        zsmooth: "best",
        showscale: true,
        connectgaps: true,
        colorscale: colorscale,
        //@ts-ignore
        contours: {
          start: 0,
          end: 1,
          size: 0.02,
          // coloring: "heatmap",
        },
        line: {
          color: "transparent",
        },
        colorbar: {
          tickvals: colorscale.map((v) => v[0]),
          ticktext: turbulenceLevelNames,
        },
      },
      // route
      {
        x: [144.6024044, 264.4097843],
        y: [parseInt(flightLevel) * 100, parseInt(flightLevel) * 100],
        mode: "lines",
        name: "2.0 deg",
        showlegend: false,
        line: {
          color: "red",
          dash: "dot",
        },
      },
      {
        x: [264.4097843, fl2DistanceML[flightLevel], 394.2258749],
        y: [parseInt(flightLevel) * 100, parseInt(flightLevel) * 100, 16000],
        mode: "lines",
        name: "2.0 deg",
        showlegend: false,
        line: {
          color: "red",
        },
      },
      // waypoint text
      {
        x: waypointDistanceMileList,
        y: waypointDistanceMileList.map(() => Math.max(...verticalFtList)),
        mode: "text",
        text: waypointList,
        textposition: "top right",
        type: "scatter",
        showlegend: false,
        textfont: { size: 10 },
      },
    ]);

    setLayout({
      width: 1000,
      height: 300,
      margin: { l: 55, r: 40, t: 10, b: 10, pad: 0 },
      xaxis: {
        showticklabels: false,
      },
      yaxis: {
        title: {
          text: "Altitude [ft]",
        },
      },
      annotations: [],
      images: windImages,
      //@ts-ignore
      shapes,
      dragmode: false,
    });
  }, [windData, crossSection]);

  useEffect(() => {
    if (!data) return;
    if (!layout) return;

    Plotly.newPlot(plotId, data, layout, { displayModeBar: false }).then(
      (gd) => {
        // rotate wind
        const images = d3
          .select(gd)
          .select(".layer-above")
          .select(".imagelayer")
          .selectAll("image");
        images.nodes().forEach((image, i) => {
          const windImage = layout.images ? layout.images[i] : null;
          if (!windImage) return;

          const { barbKnot, direction } = windImage as Partial<Plotly.Image> & {
            barbKnot: number;
            direction: number;
          };

          const IMAGE_HEIGHT = 495;
          const IMAGE_WIDTH = 127;
          const selected = d3.select(image);
          const imageHeight = (selected as any).attr("height");

          const translateX =
            -(imageHeight / IMAGE_HEIGHT) * ((IMAGE_WIDTH + 1) / 2);
          const translateY =
            (imageHeight / IMAGE_HEIGHT) * ((IMAGE_HEIGHT + 1) / 2);

          selected.attr(
            "transform",
            `
            rotate(${direction},${selected.attr("x")},${selected.attr("y")})
            translate(${translateX}, ${-translateY})
            `
          );
        });

        if (isToImage) {
          Plotly.toImage(gd, { format: "png", width: 1000, height: 300 }).then(
            (dataUrl) => {
              const img = d3.select(`#${imgId}`);
              img.attr("src", dataUrl);
              Plotly.purge(plotId);
            }
          );
        }
      }
    );
  }, [data, layout]);

  if (isWindDataLoading || isCrossSectionLoading) {
    return <></>;
  }

  if (!windData || !crossSection) {
    return <>No data.</>;
  }

  if (windDataError || crossSectionError) {
    return <>Something wrong!</>;
  }

  return (
    <Stack>
      <Box>
        {isToImage ? (
          <>
            <div id={plotId} />
            <img id={imgId} width={1000} height={300} />
          </>
        ) : (
          <div id={plotId} />
        )}
      </Box>
    </Stack>
  );
};
