import { LinearProgress, Link, TableContainer } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import html2canvas from "html2canvas";
import * as React from "react";
import { useParams } from "react-router-dom";
import HomeAppBar from "../../../../components/HomeAppBar";
import PdfComponent from "../../../../components/PdfComponent";
import PilotDashboardDrawer from "../../../../components/PilotDashboardDrawer";
import Turbulence from "../../../../components/Turbulence";
import { useFlightDetailQuery } from "../../../../services/dashboard-api";
import { orangeColor } from "../../../../theme";

(window as any).html2canvas = html2canvas;
// https://github.com/MrRio/jsPDF/issues/1225
const { jsPDF } = require("jspdf");

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface RouterParams {
  callSign: string;
  departureDateUtc: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Data {
  fpa: string;
  fuel_saving: string;
  action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "fpa",
    numeric: false,
    label: "FPA",
  },
  {
    id: "fuel_saving",
    numeric: false,
    label: "Fuel score",
  },
  {
    id: "action",
    numeric: false,
    label: "Action",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}
    </Toolbar>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? orangeColor : orangeColor,
  },
}));

export default function PilotFlightInformationDetail() {
  const { callSign, departureDateUtc } = useParams<RouterParams>();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openDialogDownload, setOpenDialogDownload] = React.useState(false);

  const handleClickOpenDownload = () => {
    setOpenDialogDownload(true);
    if (route) {
      selected.map((fpaDegree) => {
        const input = document.getElementById("sheet" + fpaDegree)!; // idを走査する
        html2canvas(input, { scale: 1.0 }).then((canvas: any) => {
          const imgData = canvas.toDataURL("image/svg", 1.0); // 一度svgにする。pngでもjpegでもok
          let pdf = new jsPDF(); // pdfを生成
          pdf.addImage(
            imgData,
            "SVG",
            5,
            10,
            canvas.width / 5,
            canvas.height / 5
          ); //　ここが大変だった
          pdf.save(`${callSign}_${departureDateUtc}_fpa${fpaDegree}.pdf`); // ダウンロードが開始される .pdf
        });
      });
    }
  };

  const handleCloseDownload = () => {
    setOpenDialogDownload(false);
  };

  const [openDialogApprove, setOpenDialogApprove] = React.useState(false);

  const handleClickOpenApprove = () => {
    setOpenDialogApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenDialogApprove(false);
  };

  const {
    data: route,
    isLoading: isLoadingRoute,
    error: errorRoute,
  } = useFlightDetailQuery([callSign, departureDateUtc]);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("fpa");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && route) {
      const newSelecteds = route.fuel_saving_list.map((n) => n.fpa);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const FlightInfoComponent = () => (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              {route && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={route.fuel_saving_list.length}
                />
              )}
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
                {route &&
                  stableSort(
                    route.fuel_saving_list,
                    getComparator(order, orderBy)
                  ).map((row, index) => {
                    const isItemSelected = isSelected(row.fpa);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.fpa)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.fpa}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link
                            href={
                              "/pilot/call-signs/" +
                              callSign +
                              "/departure-date-utc/" +
                              departureDateUtc +
                              "/fpa"
                            }
                          >
                            {row.fpa}
                          </Link>
                        </TableCell>
                        <TableCell style={{ width: 900 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={9}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={row.fuel_saving}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "bold",
                                  color: orangeColor,
                                }}
                                display="inline"
                              >
                                {Math.round(row.fuel_saving * 10) / 10}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={handleClickOpenApprove}
                            >
                              Aprrove
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleClickOpenDownload}
                            >
                              Download
                            </Button>
                            <Button
                              variant="contained"
                              href={
                                "/pilot/call-signs/" +
                                callSign +
                                "/departure-date-utc/" +
                                departureDateUtc +
                                "/fpa"
                              }
                            >
                              Detail
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </React.Fragment>
  );

  const DialogComponent = () => (
    <>
      <Dialog
        open={openDialogDownload}
        onClose={handleCloseDownload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Generating..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It may take several tens of seconds. <br />
            Close this dialog when the file is downloaded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDownload}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogApprove}
        onClose={handleCloseApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you approve this plan?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseApprove}>Cancel</Button>
          <Button onClick={handleCloseApprove} color="warning">
            APPV
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  if (errorRoute) {
    return <>Somegthing wrong...</>;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <HomeAppBar
          open={open}
          title={callSign}
          onClick={handleDrawerOpen}
        ></HomeAppBar>
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          {!value && (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpenDownload}
              >
                Download the checks
              </Button>
            </Stack>
          )}
          {value > 0 && (
            <Container fixed>
              <Box sx={{ height: "4.4vh" }} />
            </Container>
          )}
          <DialogComponent></DialogComponent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="FPA List" {...a11yProps(0)} />
              <Tab label="Turbulence Map" {...a11yProps(1)} />
              <Tab label="Flight Plan" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {/* FPA List */}
          <TabPanel value={value} index={0}>
            <Container>
              {isLoadingRoute && <LinearProgress />}
              {!isLoadingRoute && <FlightInfoComponent />}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ opacity: 0.0 }}>
                    {route &&
                      selected.map((fpaDegree) => (
                        <div
                          id={"sheet" + fpaDegree}
                          style={{ backgroundColor: "#222222" }}
                        >
                          <PdfComponent
                            {...{ route, fpaDegree }}
                          ></PdfComponent>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </TabPanel>
          {/* Turbulence map */}
          <TabPanel value={value} index={1}>
            <Turbulence></Turbulence>
          </TabPanel>
          {/* Flight plan */}
          <TabPanel value={value} index={2}>
            Flight Plan
          </TabPanel>
        </Main>
      </Box>
    </>
  );
}
