import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import * as React from "react";
import ariesConfigs from "../configs/aries-configs";
import { useFlightListQuery } from "../services/dashboard-api";

interface Data {
  PK: string;
  scheduled_departure_time_utc: string;
  departure_airport: string;
  arrival_airport: string;
  checkpoint_waypoint: string;
  checkpoint_alt: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "PK",
    numeric: false,
    label: "Call Sign",
  },
  {
    id: "scheduled_departure_time_utc",
    numeric: false,
    label: "Scheduled departure time[UTC]",
  },
  {
    id: "departure_airport",
    numeric: false,
    label: "Departure Airport",
  },
  {
    id: "arrival_airport",
    numeric: false,
    label: "Arrival Airport",
  },
  {
    id: "checkpoint_waypoint",
    numeric: false,
    label: "Default Waypoint",
  },
  {
    id: "checkpoint_alt",
    numeric: true,
    label: "Default Altitude",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

type Props = {
  order: Order;
  orderBy: keyof Data;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
};

export default function PilotSettingsTable(props: Props) {
  const account = "APJ";
  let departureDateUtc: string;
  if (ariesConfigs.ariesEnv === "sandbox") {
    departureDateUtc = "2022-07-20";
  } else {
    departureDateUtc = dayjs().format("YYYY-MM-DD");
  }
  const { data: flightInfos, isLoading } = useFlightListQuery([
    account,
    departureDateUtc,
  ]);

  const [waypoint, setWaypoint] = React.useState("");
  const [flightLevel, setFlightLevel] = React.useState<number>(16000);
  const flightLevelList: number[] = [160, 170, 180, 190, 200];

  if (isLoading) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Typography sx={{ fontSize: "1.2rem" }}>
        {flightInfos && flightInfos[0].SK.slice(-10)}
      </Typography>
      <Table size="small">
        <EnhancedTableHead
          order={props.order}
          orderBy={props.orderBy}
          onRequestSort={props.onRequestSort}
        />
        <TableBody>
          {flightInfos &&
            stableSort(
              flightInfos,
              getComparator(props.order, props.orderBy)
            ).map((row, index) => {
              return (
                <TableRow key={row.PK.slice(7)}>
                  <TableCell>{row.PK.slice(7)}</TableCell>
                  <TableCell>{row.scheduled_departure_time_utc}</TableCell>
                  <TableCell>{row.departure_airport}</TableCell>
                  <TableCell>{row.arrival_airport}</TableCell>
                  <TableCell>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="waypoint-select-helper-label">
                        Waypoint
                      </InputLabel>
                      <Select
                        labelId="waypoint-select-helper-label"
                        id="waypoint-select-helper"
                        value={waypoint}
                        label="waypoint"
                        onChange={(e) => {
                          setWaypoint(e.target.value);
                        }}
                      >
                        {row.navigation.map((waypoint) => (
                          <MenuItem value={waypoint[0]}>{waypoint[0]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="fl-select-helper-label">FL</InputLabel>
                      <Select
                        labelId="fl-select-helper-label"
                        id="fl-select-helper"
                        value={flightLevel}
                        label="FL"
                        onChange={(e) => {
                          setFlightLevel(e.target.value as number);
                        }}
                      >
                        {flightLevelList.map((fl) => (
                          <MenuItem value={fl}>{fl}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
