import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import amplifyConfigs from "./configs/amplify-configs";
import PilotFlightInformationAddition from "./pages/pilot/PilotFlightInformationAddition";
import PilotFlightInformationDeletion from "./pages/pilot/PilotFlightInformationDeletion";
import PilotFlightInformationList from "./pages/pilot/PilotFlightInformationList";
import PilotSettings from "./pages/pilot/PilotSettings";
import PilotFlightInformationDetail from "./pages/pilot/call-signs/departure-date-utc/PilotFlightInformationDetail";
import PilotFlightLevelFpaDetail from "./pages/pilot/call-signs/departure-date-utc/flight-level/PilotFlightLevelFpaDetail";
import PilotFlightLevelFpaList from "./pages/pilot/call-signs/departure-date-utc/flight-level/PilotFlightLevelFpaList";
import PilotFpaDetail from "./pages/pilot/call-signs/departure-date-utc/fpa/PilotFpaDetail";
import "./styles/globals.css";

Amplify.configure(amplifyConfigs);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={PilotFlightInformationList} />
          <Route exact path="/pilot/settings" component={PilotSettings} />
          <Route
            exact
            path="/pilot/flight-information-addition"
            component={PilotFlightInformationAddition}
          />
          <Route
            exact
            path="/pilot/flight-information-deletion"
            component={PilotFlightInformationDeletion}
          />
          <Route
            exact
            path="/pilot/call-signs/:callSign/departure-date-utc/:departureDateUtc"
            component={PilotFlightInformationDetail}
          />
          <Route
            exact
            path="/pilot/call-signs/:callSign/departure-date-utc/:departureDateUtc/flight-level"
            component={PilotFlightLevelFpaList}
          />
          <Route
            exact
            path="/pilot/call-signs/:callSign/departure-date-utc/:departureDateUtc/flight-level/:flightLevel"
            component={PilotFlightLevelFpaDetail}
          />
          <Route
            exact
            path="/pilot/call-signs/:callSign/departure-date-utc/:departureDateUtc/fpa"
            component={PilotFpaDetail}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
