import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import HomeAppBar from "../../components/HomeAppBar";
import PilotDashboardDrawer from "../../components/PilotDashboardDrawer";
import { useDeleteFlightInfoMutation } from "../../services/dashboard-api";
import { Post } from "../../services/types";

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function PilotFlightInformationDeletion() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [addPost, { isLoading }] = useDeleteFlightInfoMutation();

  const handleDeletePost = async (
    data: Pick<Post, "method" | "call_sign" | "departure_date_utc">
  ) => {
    try {
      await addPost({ ...data, method: "flight-deletion" }).unwrap();
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<Post, "method" | "call_sign" | "departure_date_utc">>();
  const onSubmit: SubmitHandler<
    Pick<Post, "method" | "call_sign" | "departure_date_utc">
  > = (data) => handleDeletePost(data);

  const FlightInfoComponent = () => (
    <Grid container spacing={1}>
      <Box>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "0.8rem" }}
            color="text.secondary"
            gutterBottom
          >
            Call Sign
          </Typography>
          <TextField
            defaultValue={"APJ236"}
            {...register("call_sign", { required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "0.8rem" }}
            color="text.secondary"
            gutterBottom
          >
            Departure Date[UTC]
          </Typography>
          <TextField
            defaultValue={"2022-07-01"}
            {...register("departure_date_utc", { required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Delete
          </Button>
        </Grid>
      </Box>
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <HomeAppBar
          open={open}
          title={"Delete Flight Information"}
          onClick={handleDrawerOpen}
        ></HomeAppBar>
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          {isLoading && <LinearProgress />}
          {!isLoading && (
            <>
              <FlightInfoComponent></FlightInfoComponent>
            </>
          )}
        </Main>
      </Box>
    </>
  );
}
