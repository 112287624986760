import html2canvas from "html2canvas";
(window as any).html2canvas = html2canvas;
const { jsPDF } = require("jspdf");

export async function MultipleDownloadProcess(props: {
  inputs: HTMLElement[];
  savedFileName: string;
}) {
  let pdf = new jsPDF("p", "mm", "a4", true); // A4 size page of PDF
  let html2canvasOptions = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: null,
    imageTimeout: 15000,
    logging: true,
    scale: 2,
    useCORS: true,
    width: 1350,
    windowWidth: 1350,
  };

  const inputsLength = props.inputs.length;
  for (let index = 0; index < inputsLength; index++) {
    let input = props.inputs[index];

    if (window.screen.width < 1024) {
      input.setAttribute("content", "width=1200px");
    }
    const element = input;
    const currentDisplayValue = element.style.display;
    element.style.display = "block";

    const canvas = await html2canvas(element, html2canvasOptions);

    // Few necessary setting options
    const contentDataURL = canvas.toDataURL("image/png");
    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(
      contentDataURL,
      "PNG",
      0,
      position,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(
        contentDataURL,
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;
    }

    if (window.screen.width < 1024) {
      input.setAttribute("content", "width=device-width, initial-scale=1");
    }

    element.style.display = currentDisplayValue;

    if (index < inputsLength - 1) {
      pdf.addPage();
    }
  }

  pdf.save(props.savedFileName); // Generated PDF
  // pdf.output("dataurlnewwindow");
}

export default async function DownloadProcess(props: {
  input: HTMLElement;
  savedFileName: string;
}) {
  if (window.screen.width < 1024) {
    props.input.setAttribute("content", "width=1200px");
  }
  const element = props.input;
  const currentDisplayValue = element.style.display;
  element.style.display = "block";

  let html2canvasOptions = {
    allowTaint: true,
    removeContainer: true,
    backgroundColor: null,
    imageTimeout: 15000,
    logging: true,
    scale: 2,
    useCORS: true,
    width: 1350,
    windowWidth: 1350,
  };

  let pdf = new jsPDF("p", "mm", "a4", true); // A4 size page of PDF

  const canvas = await html2canvas(element, html2canvasOptions);

  // Few necessary setting options
  const contentDataURL = canvas.toDataURL("image/png");
  const imgWidth = 210;
  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;

  pdf.addImage(
    contentDataURL,
    "PNG",
    0,
    position,
    imgWidth,
    imgHeight,
    undefined,
    "FAST"
  );
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    pdf.addPage();
    pdf.addImage(
      contentDataURL,
      "PNG",
      0,
      position,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;
  }
  pdf.save(props.savedFileName); // Generated PDF

  if (window.screen.width < 1024) {
    props.input.setAttribute("content", "width=device-width, initial-scale=1");
  }

  element.style.display = currentDisplayValue;
}
