import {
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import HomeAppBar from "../../components/HomeAppBar";
import PilotDashboardDrawer from "../../components/PilotDashboardDrawer";
import { useManualInputMutation } from "../../services/dashboard-api";
import { Post } from "../../services/types";

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function PilotFlightInformationAddition() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const statusList = ["Before Departure", "In Flight", "Arrived", "Cancelled"];

  const [addPost, { isLoading }] = useManualInputMutation();

  const handleAddPost = async (data: Post) => {
    try {
      await addPost({ ...data, method: "manual-input" }).unwrap();
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Post>();
  const onSubmit: SubmitHandler<Post> = (data) => handleAddPost(data);

  const FlightInfoComponent = () => (
    <Grid item xs={12} container spacing={1}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Status
            </Typography>
            <Select
              defaultValue={"Before Departure"}
              {...register("status", { required: true })}
            >
              {statusList.map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Call Sign
            </Typography>
            <TextField
              defaultValue={"APJ236"}
              {...register("call_sign", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Departure Date[UTC]
            </Typography>
            <TextField
              defaultValue={"2022-07-01"}
              {...register("departure_date_utc", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Arrival Airport
            </Typography>
            <TextField
              defaultValue={"RJBB/KIX"}
              {...register("arrival_airport", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Arrival Airport Latitude
            </Typography>
            <TextField
              defaultValue={"N34261"}
              {...register("arrival_airport_latitude", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Arrival Airport Longitude
            </Typography>
            <TextField
              defaultValue={"E135140"}
              {...register("arrival_airport_longitude", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Datetime[UTC]
            </Typography>
            <TextField
              defaultValue={"2022-07-01 06:24:00"}
              {...register("date_time_utc", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Departure Airport
            </Typography>
            <TextField
              defaultValue={"ROAH/OKA"}
              {...register("departure_airport", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Scheduled departure time[UTC]
            </Typography>
            <TextField
              defaultValue={"0600Z"}
              {...register("scheduled_departure_time_utc", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Initial Fuel Weight[lb]
            </Typography>
            <TextField
              defaultValue={17800}
              {...register("initial_fuel_weight", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Take-off Weight[lb]
            </Typography>
            <TextField
              defaultValue={135438}
              {...register("take_off_weight", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              TOD Altitude[ft]
            </Typography>
            <TextField
              defaultValue={39000}
              {...register("tod_altitude", { required: true })}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              sx={{ fontSize: "0.8rem" }}
              color="text.secondary"
              gutterBottom
            >
              Navigation
            </Typography>
            <TextField
              defaultValue={`[["MADOG", "N32075", "E132238"], ["SUC", "N32454", "E132598"], ["STORK", "N32568", "E133146"], ["BRAVE", "N33269", "E133536"], ["KARIN", "N33404", "E134112"]]`}
              {...register("navigation", { required: true })}
              fullWidth={true}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box>
            <Typography
              sx={{ fontSize: "1.0rem" }}
              color="text.secondary"
              gutterBottom
            >
              Checkpoint
            </Typography>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Waypoint
              </Typography>
              <TextField
                defaultValue={"KARIN"}
                {...register("checkpoint_waypoint", { required: true })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: "0.8rem" }}
                color="text.secondary"
                gutterBottom
              >
                Altitude[ft]
              </Typography>
              <TextField
                defaultValue={16000}
                {...register("checkpoint_alt", { required: true })}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <HomeAppBar
          open={open}
          title={"Add Flight Information"}
          onClick={handleDrawerOpen}
        ></HomeAppBar>
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          {isLoading && <LinearProgress />}
          {!isLoading && (
            <>
              <FlightInfoComponent></FlightInfoComponent>
            </>
          )}
        </Main>
      </Box>
    </>
  );
}
