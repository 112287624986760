import { LinearProgress, Link, TableContainer } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useParams } from "react-router-dom";
import AltitudeLimitIndicationTable from "../../../../../components/AltitudeLimitIndicationTable";
import DownloadProcess, {
  MultipleDownloadProcess,
} from "../../../../../components/DownloadProcess";
import HomeAppBar from "../../../../../components/HomeAppBar";
import PdfFlightLevelComponent from "../../../../../components/PdfFlightLevelComponent";
import PilotDashboardDrawer from "../../../../../components/PilotDashboardDrawer";
import WindData from "../../../../../components/WindData";
import {
  useFlightDetailQuery,
  useFlightInfoQuery,
} from "../../../../../services/dashboard-api";
import {
  FuelConsumptionFpa,
  Route,
  RouteComponent,
} from "../../../../../services/types";
import theme, { grayColor } from "../../../../../theme";
import AltitudeLimitIndicationTable2p0 from "../../../../../components/AltitudeLimitIndicationTable2p0";

const drawerWidth: number = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface RouterParams {
  callSign: string;
  departureDateUtc: string;
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  route: Route;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount, route } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" rowSpan={3}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        <TableCell rowSpan={3}>Initial altitude</TableCell>
        <TableCell rowSpan={1} colSpan={11}>
          Expected Fuel Consumption [lb]
        </TableCell>
        <TableCell rowSpan={3}></TableCell>
      </TableRow>
      <TableRow>
        {/* <TableCell rowSpan={2}>Non-FPA</TableCell> */}
        <TableCell rowSpan={1} colSpan={11}>
          FPA [deg]
        </TableCell>
      </TableRow>
      <TableRow>
        {route.fpa_deg_list.map((fpa) => (
          <TableCell align="right" key={fpa}>
            {fpa}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          FPA List
        </Typography>
      )}
    </Toolbar>
  );
};

export default function PilotFlightLevelFpaList() {
  const { callSign, departureDateUtc } = useParams<RouterParams>();

  const [open, setOpen] = React.useState(false);
  const [downloadProgress, setDownloadProgress] = React.useState<
    "determinate" | "indeterminate"
  >("indeterminate");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openDialogDownload, setOpenDialogDownload] = React.useState(false);

  const handleClickOpenDownloadTheChecks = () => {
    setDownloadProgress("indeterminate");
    setOpenDialogDownload(true);
    if (route) {
      const inputs = selected.map((flightLevel) => {
        const input = document.getElementById("sheet" + flightLevel)!; // idを走査する
        return input;
      });
      MultipleDownloadProcess({
        inputs: inputs,
        savedFileName: `${callSign}_${departureDateUtc}.pdf`,
      }).then(() => setDownloadProgress("determinate"));
    }
  };

  const handleClickOpenDownloadOne = (flightLevel: String) => {
    setDownloadProgress("indeterminate");
    setOpenDialogDownload(true);
    if (route) {
      const input = document.getElementById("sheet" + flightLevel)!; // idを走査する
      DownloadProcess({
        input: input,
        savedFileName: `${callSign}_${departureDateUtc}_FL${flightLevel}.pdf`,
      }).then(() => setDownloadProgress("determinate"));
    }
  };

  const handleCloseDownload = () => {
    setOpenDialogDownload(false);
  };

  const {
    data: flightInfo,
    isLoading: isLoadingFlightInfo,
    error: errorFlightInfo,
  } = useFlightInfoQuery([callSign, departureDateUtc]);

  const {
    data: route,
    isLoading: isLoadingRoute,
    error: errorRoute,
  } = useFlightDetailQuery([callSign, departureDateUtc]);

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && route) {
      const newSelecteds = route.flight_level_list.map((n) => String(n));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const FlightInfoComponent = () => (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, overflowX: "auto" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              {route && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={route.flight_level_list.length}
                  route={route}
                />
              )}
              <TableBody>
                {route && (
                  <>
                    <FlightLevelFpaTable
                      {...{ route: route.FL390, flightLevel: 390 }}
                    ></FlightLevelFpaTable>
                    <FlightLevelFpaTable
                      {...{ route: route.FL370, flightLevel: 370 }}
                    ></FlightLevelFpaTable>
                    <FlightLevelFpaTable
                      {...{ route: route.FL350, flightLevel: 350 }}
                    ></FlightLevelFpaTable>
                    <FlightLevelFpaTable
                      {...{ route: route.FL330, flightLevel: 330 }}
                    ></FlightLevelFpaTable>
                    <FlightLevelFpaTable
                      {...{ route: route.FL310, flightLevel: 310 }}
                    ></FlightLevelFpaTable>
                    <FlightLevelFpaTable
                      {...{ route: route.FL290, flightLevel: 290 }}
                    ></FlightLevelFpaTable>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </React.Fragment>
  );

  function FlightLevelFpaTable(props: {
    route: RouteComponent;
    flightLevel: number;
  }) {
    const isItemSelected = isSelected(String(props.flightLevel));
    const labelId = `enhanced-table-checkbox-${props.flightLevel}`;

    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, String(props.flightLevel))}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={props.flightLevel}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox" key={`${props.flightLevel}-check`}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell
          key={`${props.flightLevel}-cell`}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Link
            href={
              "/pilot/call-signs/" +
              callSign +
              "/departure-date-utc/" +
              departureDateUtc +
              "/flight-level/" +
              String(props.flightLevel)
            }
          >
            {"FL" + String(props.flightLevel)}
          </Link>
        </TableCell>
        {props.route.fpa_deg_list.map((fpaDegree) => (
          <>
            {(props.route.possible_fpa_deg_list || []).includes(fpaDegree) &&
              props.route.fuel_consumption[
                fpaDegree as keyof FuelConsumptionFpa
              ] ===
                Math.min(
                  ...props.route.fpa_deg_list.map(
                    (fpaDegree2) =>
                      props.route.fuel_consumption[
                        fpaDegree2 as keyof FuelConsumptionFpa
                      ]
                  )
                ) && (
                <TableCell
                  align="right"
                  key={fpaDegree}
                  sx={{ backgroundColor: theme.palette.grey[100] }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: theme.palette.grey[400],
                    }}
                    display="inline"
                  >
                    {Math.round(
                      props.route.fuel_consumption[
                        fpaDegree as keyof FuelConsumptionFpa
                      ]
                    )}
                  </Typography>
                </TableCell>
              )}
            {(props.route.possible_fpa_deg_list || []).includes(fpaDegree) &&
              props.route.fuel_consumption[
                fpaDegree as keyof FuelConsumptionFpa
              ] !==
                Math.min(
                  ...props.route.fpa_deg_list.map(
                    (fpaDegree2) =>
                      props.route.fuel_consumption[
                        fpaDegree2 as keyof FuelConsumptionFpa
                      ]
                  )
                ) && (
                <TableCell
                  key={`fuel-comsumption-${fpaDegree}`}
                  align="right"
                  sx={{ backgroundColor: theme.palette.grey[100] }}
                >
                  <Typography
                    style={{
                      color: theme.palette.grey[400],
                    }}
                    display="inline"
                  >
                    {Math.round(
                      props.route.fuel_consumption[
                        fpaDegree as keyof FuelConsumptionFpa
                      ]
                    )}
                  </Typography>
                </TableCell>
              )}
            {!(props.route.possible_fpa_deg_list || []).includes(fpaDegree) && (
              <TableCell align="right">
                <Typography
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: grayColor,
                  }}
                  display="inline"
                >
                  {Math.round(
                    props.route.fuel_consumption[
                      fpaDegree as keyof FuelConsumptionFpa
                    ]
                  )}
                </Typography>
              </TableCell>
            )}
          </>
        ))}
        <TableCell>
          <Stack spacing={2} justifyContent="space-evenly" direction="row">
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleClickOpenDownloadOne(String(props.flightLevel))
              }
            >
              Download
            </Button>
            <Button
              variant="contained"
              href={
                "/pilot/call-signs/" +
                callSign +
                "/departure-date-utc/" +
                departureDateUtc +
                "/flight-level/" +
                String(props.flightLevel)
              }
            >
              Detail
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  const DownloadDialogComponent = () => (
    <>
      <Dialog
        open={openDialogDownload}
        onClose={handleCloseDownload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Generating..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It may take several tens of seconds. <br />
            Close this dialog when the file is downloaded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDownload}>Close</Button>
        </DialogActions>
        <LinearProgress variant={downloadProgress} />
      </Dialog>
    </>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <HomeAppBar
          open={open}
          title={`${callSign} / ${departureDateUtc}`}
          onClick={handleDrawerOpen}
        ></HomeAppBar>
        <PilotDashboardDrawer
          open={open}
          onClick={handleDrawerClose}
        ></PilotDashboardDrawer>
        <Main>
          <Toolbar />
          <DownloadDialogComponent></DownloadDialogComponent>
          <Box sx={{ m: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mb: "1rem" }}
              spacing={2}
            >
              <Button variant="contained" href={"/"}>
                Back
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpenDownloadTheChecks}
                disabled={selected.length === 0}
              >
                Download selected
              </Button>
            </Stack>
            {/* )} */}

            {isLoadingRoute && <LinearProgress />}
            {!isLoadingRoute && <FlightInfoComponent />}

            <Box sx={{ my: "1rem" }}>
              <WindData
                callSign={callSign}
                departureDateUtc={departureDateUtc}
              />
            </Box>

            <AltitudeLimitIndicationTable2p0 />
          </Box>
        </Main>
      </Box>
      {/* For printing */}
      <div style={{ opacity: 0.0 }}>
        {route &&
          flightInfo &&
          route.flight_level_list.map((flightLevel) => (
            <div
              id={"sheet" + String(flightLevel)}
              key={"sheet" + String(flightLevel)}
              style={{ display: "none" }}
            >
              {flightLevel === 290 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL290}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
              {flightLevel === 310 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL310}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
              {flightLevel === 330 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL330}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
              {flightLevel === 350 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL350}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
              {flightLevel === 370 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL370}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
              {flightLevel === 390 && (
                <PdfFlightLevelComponent
                  flightInfo={flightInfo}
                  route={route.FL390}
                  flightLevel={String(flightLevel)}
                  callSign={callSign}
                  departureDateUtc={departureDateUtc}
                  isToImage={true}
                ></PdfFlightLevelComponent>
              )}
            </div>
          ))}
      </div>
    </>
  );
}
