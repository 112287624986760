import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link as MuiLink,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Fragment, useState } from "react";
import {
  AltitudeAtWaypointsComponents,
  FlightInfo,
  FuelConsumptionFpa,
  RouteComponent,
  TimeFromHere,
} from "../services/types";
import theme from "../theme";
import AltitudeLimitIndicationTable2p0 from "./AltitudeLimitIndicationTable2p0";
import { CrossSection } from "./CrossSection";
import WindData from "./WindData";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PdfFlightLevelComponent({
  flightInfo,
  route,
  flightLevel,
  callSign,
  departureDateUtc,
  isToImage = false,
}: {
  flightInfo: FlightInfo;
  route: RouteComponent;
  flightLevel: string;
  callSign: string;
  departureDateUtc: string;
  isToImage?: boolean;
}) {
  const [crossSectionDialogOpen, setCrossSectionDialogOpen] = useState(false);

  function Season(month: string) {
    const winter: string[] = ["01", "02", "12"];

    if (winter.includes(month) || route.PK.slice(7).match(/winter/)) {
      return "winter";
    } else {
      return "summer";
    }
  }
  const season = Season(route.SK.slice(22, 24));

  const secondsToMinutes = (seconds: number) => {
    return `${Math.floor(seconds / 60)}'${(
      "0" + (seconds % 60).toString()
    ).slice(-2)}`;
  };

  return (
    <>
      <Paper>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Stack justifyContent={"space-evenly"} direction={"row"}>
              <Box>
                <Typography sx={{ fontSize: "1rem" }} display="inline">
                  Initial Altitude
                </Typography>
                &nbsp;
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  display="inline"
                >
                  {`FL${flightLevel}`}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "1rem" }} display="inline">
                  Target
                </Typography>
                &nbsp;
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  display="inline"
                >
                  {flightInfo && `${flightInfo.checkpoint_waypoint}`}
                </Typography>
                &nbsp;
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  display="inline"
                >
                  {flightInfo && `${flightInfo.checkpoint_alt}`}
                </Typography>
                <Typography sx={{ fontSize: "1rem" }} display="inline">
                  ft
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "1rem" }} display="inline">
                  From
                </Typography>
                &nbsp;
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  display="inline"
                >
                  {route && route.navigation[0]}
                </Typography>
                &nbsp;
                <Typography sx={{ fontSize: "1rem" }} display="inline">
                  To
                </Typography>
                &nbsp;
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  display="inline"
                >
                  {route && route.navigation[route.navigation.length - 1]}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {/* Header */}
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Table size="small" sx={{ p: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={3} align="center">
                    FPA [deg]
                  </TableCell>
                  <TableCell
                    rowSpan={1}
                    colSpan={route && 1 + route.navigation.slice(1).length}
                    align="center"
                  >
                    FPA Entry Gate Point
                  </TableCell>
                  <TableCell rowSpan={3} align="center">
                    FPA [deg]
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    colSpan={route && route.navigation.slice(1).length}
                    align="center"
                  >
                    Expected Altitude [FL]
                  </TableCell>
                  <TableCell rowSpan={3} align="center">
                    Est. Fuel [lb]
                  </TableCell>
                  <TableCell rowSpan={3} align="center">
                    Est. Time
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} align="center">
                    Altitude [FL]
                  </TableCell>
                  <TableCell
                    colSpan={route && route.navigation.slice(1).length}
                    align="center"
                  >
                    Distance From Waypoints [NM]
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* waypoint col names */}
                  {route &&
                    route.navigation.slice(1).map((name) => (
                      <TableCell
                        sx={{ width: "5rem" }}
                        key={name}
                        align="center"
                      >
                        {name}
                      </TableCell>
                    ))}
                  {route &&
                    route.navigation.slice(1).map((name) => (
                      <TableCell
                        sx={{ width: "5rem" }}
                        key={name}
                        align="center"
                      >
                        {name}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              {/* Content */}
              <TableBody>
                <Fragment>
                  {route &&
                    route.fpa_deg_list.map((fpaDegree, index) => (
                      <StyledTableRow key={index}>
                        {/* FPA */}
                        <TableCell align="right">{fpaDegree}</TableCell>
                        {/* Gate */}
                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={route.fpa_deg_list.length}
                          >
                            {Number(flightLevel) - 10}
                          </TableCell>
                        )}
                        {route.distance_to_waypoints[
                          fpaDegree as keyof typeof route.distance_to_waypoints
                        ].map(
                          (row) =>
                            Number(row.FL) === Number(flightLevel) - 10 &&
                            route.navigation.slice(1).map((name) => {
                              let value = "";
                              if (
                                fpaDegree === "2.5" &&
                                flightLevel === "370" &&
                                name == "STORK"
                              ) {
                                value = "-10";
                              } else if (
                                fpaDegree === "2.0" &&
                                flightLevel === "370" &&
                                name == "SUC"
                              ) {
                                value = "-12";
                              } else {
                                value =
                                  row[name as keyof typeof row].split(".")[0];
                              }
                              return (
                                <TableCell align="right" key={name}>
                                  {value}
                                </TableCell>
                              );
                            })
                        )}
                        {/* FPA */}
                        <TableCell align="right">{fpaDegree}</TableCell>
                        {/* Altitude */}
                        {route &&
                          route.navigation.slice(1).map((name) => (
                            <TableCell align="right" key={name}>
                              {route &&
                                Math.round(
                                  route.altitude_at_waypoints[
                                    name as keyof typeof route.altitude_at_waypoints
                                  ][
                                    fpaDegree as keyof AltitudeAtWaypointsComponents
                                  ] / 100
                                ).toLocaleString() < flightLevel &&
                                Math.round(
                                  route.altitude_at_waypoints[
                                    name as keyof typeof route.altitude_at_waypoints
                                  ][
                                    fpaDegree as keyof AltitudeAtWaypointsComponents
                                  ] / 100
                                ).toLocaleString()}
                            </TableCell>
                          ))}
                        <TableCell
                          align="right"
                          sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                          <Typography
                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            color={theme.palette.grey[400]}
                            display="inline"
                          >
                            {route &&
                              Math.round(
                                route.fuel_consumption[
                                  fpaDegree as keyof FuelConsumptionFpa
                                ]
                              ).toLocaleString()}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                          <Typography
                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            color={theme.palette.grey[400]}
                            display="inline"
                          >
                            {route &&
                              secondsToMinutes(
                                route.time_from_here[1][
                                  fpaDegree as keyof TimeFromHere
                                ]
                              )}
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </Fragment>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ my: "1rem" }}>
        <AltitudeLimitIndicationTable2p0 />
      </Box>
      <Box sx={{ my: "1rem" }}>
        <WindData callSign={callSign} departureDateUtc={departureDateUtc} />
      </Box>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography>
          Cross Section Turbulence Map{" "}
          <MuiLink onClick={() => setCrossSectionDialogOpen(true)}>
            <HelpOutlineIcon fontSize={"small"} />
          </MuiLink>
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CrossSection
            callSign={callSign}
            departureDateUtc={departureDateUtc}
            flightLevel={flightLevel}
            isToImage={isToImage}
          />
        </Box>
      </Paper>
      <Dialog open={crossSectionDialogOpen}>
        <DialogTitle>Cross Section Turbulence Map</DialogTitle>
        <DialogContent>
          Cross Section Turbulence Map
          では経路に沿った乱流強度と風の情報を可視化しています。
          乱流強度は、個々の物理現象に基づく乱流指標を計算し各指標の値を組み合わせて推論しています。
          <br />
          　乱流の発生原因として、大スケールの流れから小スケールの乱流が生まれる物理現象があります(i.e.,
          カスケード)。 この現象を利用すれば、100 km のスケールの流れから、10 ~
          1 km のスケールの乱流強度を推論できます。
          利用している個々の物理現象に基づく乱流指標は、たとえば、風速の鉛直シアー、Kelvin-Helmholtz
          不安定、ジェット気流の蛇行、山を過ぎる流れの作る波、雲の生成に関わる上昇気流、などです。
          <br />
          　なお、入力データとしてアメリカの海洋大気庁 (NOAA)
          の予報を利用しています。
          <br />
          <br />※
          ダウンロードしたファイルには風の情報は含まれません。必要に応じてスクリーンショットをお使いください。
          <br />
          <ul>
            <li>
              ホームボタンのあるiPadの場合:
              トップボタンとホームボタンを同時に押してから放します。
            </li>
            <li>
              その他のiPadのモデルの場合:
              iPadの右上端にあるトップボタンとどちらかの音量ボタンを同時に押してから放します。
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCrossSectionDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
