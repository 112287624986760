import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import * as React from "react";
import TurbulenceSide from "./turbulence_side.jpeg";
import TurbulenceTop1 from "./turbulence_top1.jpeg";
import TurbulenceTop2 from "./turbulence_top2.jpeg";

function valuetext(value: number) {
  return `${Math.round((value / 100) * 24)}:00`;
}
export default function Turbulence() {
  const [value, setValue] = React.useState<number[]>([0, 40]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const [pressureHectoPascal, setPressureHectoPascal] = React.useState(200);

  const pressureList: number[] = [200, 250, 300, 350];

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Turbulence"
            />
            <FormControlLabel control={<Checkbox />} label="Wind" />
            <FormControlLabel control={<Checkbox />} label="Clouds" />
            <FormControlLabel
              control={<Checkbox />}
              label="Precipitation(Ground)"
            />
            <Typography gutterBottom>Time Line</Typography>
            <Slider
              getAriaLabel={() => "Time range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="basic-fl-select-helper-label">
                Basic FL
              </InputLabel>
              <Select
                labelId="basic-fl-select-helper-label"
                id="basic-fl-select-helper"
                value={pressureHectoPascal}
                label="basic-fl"
                onChange={(e) => {
                  setPressureHectoPascal(e.target.value as number);
                }}
              >
                {pressureList.map((pressure) => (
                  <MenuItem value={pressure}>{pressure}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Box
                component="img"
                sx={{
                  height: 350,
                  width: 700,
                  maxHeight: { xs: 500, md: 350 },
                  maxWidth: { xs: 1100, md: 700 },
                }}
                src={TurbulenceSide}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Box
                component="img"
                sx={{
                  height: 200,
                  width: 320,
                  maxHeight: { xs: 500, md: 300 },
                  maxWidth: { xs: 1000, md: 500 },
                }}
                src={TurbulenceTop1}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Box
                component="img"
                sx={{
                  height: 200,
                  width: 320,
                  maxHeight: { xs: 500, md: 300 },
                  maxWidth: { xs: 1000, md: 500 },
                }}
                src={TurbulenceTop2}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
